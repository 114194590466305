import React from "react";
import { Link } from 'gatsby'
import { elastic as Menu } from "react-burger-menu";


export default props => {
  return (
    // Pass on our props
    <Menu {...props}>
      <a className="nav-link" href='https://shop.emkit.net/shop'>Shop</a>
      {props.menuLinks.map(link => (
        <Link className="nav-link" key={link.name} to={link.link} >
          {link.name}
        </Link>
      ))}
    </Menu>
  );
};
