import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import "./layout.scss"
import "../components/sidebar.scss"
import SideBar from "../components/sidebar"
import { Waypoint } from "react-waypoint"
import { StaticQuery, graphql } from "gatsby"
import Up from './Up'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this._handleWaypointEnter = this._handleWaypointEnter.bind(this) // Bind for appropriate 'this' context
    this._handleWaypointLeave = this._handleWaypointLeave.bind(this) // Bind for appropriate 'this' context
    this._handleWaypoint = this._handleWaypoint.bind(this) // Bind for appropriate 'this' context
  }

  _handleWaypoint = function(up) {
    const header = document.getElementById("masthead")
    const bmButton = document.querySelector(".bm-burger-button")

    if (up) {
      header.classList.remove("header-mini")
      bmButton.classList.remove("button-mini")
    } else {
      header.classList.add("header-mini")
      bmButton.classList.add("button-mini")
    }
  }

  _handleWaypointEnter = function() {
    this._handleWaypoint(true)
  }
  _handleWaypointLeave = function() {
    this._handleWaypoint(false)
  }

  render() {
    const { title, slogan, children} = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
                menuLinks {
                  name
                  link
                }
              }
            }
          }
        `}
        render={data => (
          <React.Fragment>
            <div id="App" style={{ height: "100%" }}>
              <div id="outer-container">
                <Header
                  menuLinks={data.site.siteMetadata.menuLinks}
                  siteTitle={title}
                  slogan={slogan}
                ></Header>
                <SideBar
                  // noOverlay
                  menuLinks={data.site.siteMetadata.menuLinks}
                  right
                  pageWrapId={"page-wrap"}
                  outerContainerId={"outer-container"}
                />
                  <Waypoint
                    onEnter={this._handleWaypointEnter}
                    onLeave={this._handleWaypointLeave}
                    bottomOffset="100px"
                  />
                <div id="page-wrap">
                  {children}
                  <Footer></Footer>
                  <Up></Up>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      />
    )
  }
}

export default Layout
