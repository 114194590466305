import React from "react"

import "./up.scss"

class Up extends React.Component {
  componentDidMount() {
    const magnet = document.querySelector(".magnet")
    const magnetWrapper = document.querySelector(".magnet-wrapper")

    window.addEventListener("scroll", function() {
      if (window.scrollY > 0) {
        magnetWrapper.style.animation =
          "fade-in 2s cubic-bezier(0.2, 1, 0.3, 1) forwards"
        magnetWrapper.classList.add("show")
      } else {
        magnetWrapper.style.animation = "fade-out 2s forwards"
        magnetWrapper.classList.remove("show")
      }
    })
    var canvas,
      c,
      w,
      h,
      twoPI = Math.PI * 2,
      styles = [
        { c: "rgba(0,  128,255,0.02)", w: 55 },
        { c: "rgba(0,  128,255,0.05)", w: 25 },
        { c: "rgba(0,  255,255,0.10)", w: 10 },
        { c: "rgba(255,128,255,0.20)", w: 5 },
        { c: "rgba(255,255,255,1.00)", w: 1 },
      ]

    canvas = document.createElement("canvas")
    w = canvas.width = 170
    h = canvas.height = 100
    c = canvas.getContext("2d")
    magnet.appendChild(canvas)

    c.globalCompositeOperation = "lighter"
    this.int = window.setInterval(animate, 2000 / 33)

    function animate() {
      c.save()
      c.setTransform(1, 0, 0, 1, 0, 0)
      c.clearRect(0, 0, w, h)
      c.globalCompositeOperation = "source-over"
      c.fillStyle = "transparent"
      c.fillRect(0, 0, w, h)
      c.restore()
      drawBolt(w / 2, h - 10)
    }

    function drawBolt(x, y) {
      c.beginPath()
      var direction = twoPI * 0.75
      new Bolt(x, y, h / 2, 20, direction, 0)
      c.closePath()
      c.lineCap = "round"
      for (var i = 0; i < styles.length; i++) {
        c.strokeStyle = styles[i].c
        c.lineWidth = styles[i].w
        c.stroke()
      }
    }

    function Bolt(ox, oy, length, segments, direction, fork) {
      var x = ox,
        y = oy
      for (var i = 0; i < segments; i++) {
        c.moveTo(x, y)
        x += Math.cos(direction) * (length / segments)
        y += Math.sin(direction) * (length / segments)
        c.lineTo(x, y)
        var branch = Math.random() * 15 + fork
        if (fork < 3 && branch > 12) {
          new Bolt(x, y, h / 8, 5, direction, fork + 1)
        }
        direction +=
          Math.random() * (twoPI / 16) * Math.round(Math.random() * 2 - 1)
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.int)
  }

  handleClick() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }

  render() {
    return (
      <div className="magnet-wrapper fade">
        <div className="magnet" onClick={this.handleClick}></div>
      </div>
    )
  }
}

export default Up
