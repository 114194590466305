// eslint-disable-next-line no-restricted-globals
/*eslint no-restricted-globals: ["error", "event"]*/
import React from "react"
import { Link } from "gatsby"
import "./header.scss"
import Logo from "../../content/assets/pwlogo.svg"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const Header = ({ menuLinks, siteTitle, slogan}) => {
  const activeClassName = "active"

  return (
    <header id="masthead" className="site-header" role="banner">
      <div className="site-header-main">
        <div className="site-branding">
          <img
            src={Logo}
            width="50px"
            height="50px"
            alt="PowerWatcher Logo"
          ></img>
          <div className="branding-text-group">
            <p className="site-title">
              <Link
                style={{
                  boxShadow: "none",
                  textDecoration: "none",
                  color: "inherit",
                }}
                to="/"
              >
                {siteTitle}
              </Link>
            </p>
            <p className="site-description">{slogan}</p>
          </div>
        </div>
        <div className="header-right">
          <nav className="main-navigation">
            <OutboundLink className="nav-link" href="https://shop.emkit.net/shop">Shop</OutboundLink>
            {menuLinks.map(link => (
              <Link className="nav-link" key={link.name} to={link.link} activeClassName={activeClassName}>
                {link.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </header>
  )
}

export default Header
