import React from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import kebabCase from "lodash/kebabCase"

import './footer.scss'
import Facebook from '../../content/assets/social_icons/facebook.inline.svg';
import Youtube from '../../content/assets/social_icons/youtube.inline.svg';
import Twitter from '../../content/assets/social_icons/twitter.inline.svg';
import Instagram from '../../content/assets/social_icons/instagram.inline.svg';
import Phone from '../../content/assets/phone.inline.svg';
import Email from '../../content/assets/email.inline.svg';

const Footer = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
      allMarkdownRemark(limit: 2000) {
        group(field: frontmatter___tags) {
          fieldValue
          totalCount
        }
      }
    }
  `)
    const tags = data.allMarkdownRemark.group;

    return(
      <React.Fragment>
        <footer>
          <div className="upper-footer">
            <div className='center'>
              <div className="footer-widgets row">
                <div className="footer-widget">
                  <aside id="tag_cloud-3" className="widget widget_tag_cloud">
                    <h3 className="widget-title">TAGS</h3>
                    <ul className="tagcloud">
                      {tags.map(tag => (
                        <li key={tag.fieldValue}>
                          <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                            {tag.fieldValue} ({tag.totalCount})
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>
                <div className="footer-widget">
                  <aside className="widget widget_resources">
                    <h3 className="widget-title">RESOURCES</h3>		
                    <ul>
                      <li><a href="http://electroavtosam.com.ua/" title="The local EV club">ElectroAutoSam</a></li>
                      <li><a href="http://electricmobile.org.ua/" title="Electric mobile">Electric mobile</a></li>
                      <li><a href="http://electrotransport.ru/" title="Electrotransport">Electrotransport</a></li>
                    </ul>
                  </aside>	
                </div>
                <div className="footer-widget">
                  <aside className="widget widget_text">
                    <h3 className="widget-title">CONTACT</h3>
                      <div className="textwidget">
                        <ul>
                          <li>
                            <Phone></Phone>
                            <span>+38 067 573 77 22</span>
                          </li>
                          <li>
                            <Email></Email>
                            <span>Yuriy.Logvin@gmail.com</span>
                          </li>
                        </ul>
                      </div>
                  </aside>
                </div>
              </div>
              <div className="social-links">
                <ul className="soc">
                  <li>
                    <a className="icon-1" aria-label="Follow us on Twitter" href='https://powerwatcher.net'>
                        <Twitter></Twitter>
                    </a>
                  </li>
                  <li>
                    <a className="icon-2" aria-label="Follow us on Facebook" href='https://www.facebook.com/yuriy.logvin.79'>
                        <Facebook></Facebook>
                    </a>
                  </li>
                  <li>
                    <a className="icon-3" aria-label="Follow us on Instagram" href='https://powerwatcher.net'>
                        <Instagram></Instagram>
                    </a>
                  </li>
                  <li>
                    <a className="icon-4" aria-label="Follow us on Youtube" href='https://www.youtube.com/user/YLogvin/videos'>
                        <Youtube></Youtube>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="lower-footer">
            <div className="center">
              © {new Date().getFullYear()}, Power Watcher |
              {` `}
              <span className="eul"><Link to="/end-user-license-agreement/"> EULA</Link></span>
            </div>
          </div>
        </footer>
      </React.Fragment>
    )
  }

export default Footer


